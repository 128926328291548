/* TODO: Move to Uloc */
export default {
  data () {
    return {
    }
  },
  mounted () {},
  watch: {
    inFullscreen (v) {
      if (v) {
        window.addEventListener('keyup', this.listenerEsc, true)
      } else {
        window.removeEventListener('keyup', this.listenerEsc, true)
      }
    }
  },
  methods: {
    listenerEsc: function (evt) {
      if (evt.which === 27 || evt.keyCode === 27) {
        evt.preventDefault()
        evt.stopPropagation()
        this.closeOnEscapeKey()
      }
    },
    closeOnEscapeKey () {
      this.exitFullscreen()
    }
  }
}
