export const CRIAR_TAREFA = 1
export const SEGUIR_TAREFA = 2

export const Types = {
  [CRIAR_TAREFA]: (comment) => {
    return `${comment.user.name} criou esta tarefa`
  },
  [SEGUIR_TAREFA]: (comment) => {
    return `${comment.user.name} começou a seguir esta tarefa`
  }
}

export const getDescription = function (comment) {
  if(!comment.action) return
  if (typeof Types[comment.action.code] === 'undefined') {
    return 'Unknow type code'
  }
  return Types[comment.action.code](comment)
}
