<script>
import {UPopover} from "uloc-vue";
export default {
  name: 'comment-options-menu',
  components: {UPopover}
}
</script>

<template>
    <u-popover :offset="[4, 4]">
        <div class="window-context-menu">
            <ul>
                <li><a class="menuItem">Ocultar comentários do sistema</a></li>
                <!--<li><a>Alterar status</a></li>-->
                <li>
                    <a class="menuItem">
                        <u-icon name="info-circle" type="fa" color="" class="m-r-xs"></u-icon>
                        Ajuda
                    </a>
                </li>
            </ul>
        </div>
    </u-popover>
</template>
