<script>
    import {getDescription} from '@/domain/tarefas/helpers/CommentTypes'
    import {UTooltip} from "uloc-vue"
    import flowMixin from "@/components/pessoa/components/include/historico/flowMixin"
    import globalPersonMixin from "@/components/pessoa/globalPersonMixin"
    import TaskFile from "@/components/sltarefas/components/tarefa/attachments/file"

    export default {
      name: 'task-history',
      inject: ['container'],
      mixins: [flowMixin, globalPersonMixin],
      props: {
        h: {
          require: true
        }
      },
      data () {
        return {}
      },
      mounted() {
        if (!this.$router) {
          this.router = this.container.$router
        }
      },
      computed: {
        me () {
          return this.$uloc.auth.session.user.person
        },
        isOwner () {
          return this.me === Number(this.h.personId)
        },
        classes () {
          let css = []
          if (this.isSystemComment) {
            css.push('system-comment')
          }

          if (this.isOwner) {
            css.push('comment-me')
          } else {
            css.push('comment-user')
          }

          css.push('action-' + this.action)

          return css
        },
        description () {
          /*if (this.isSystemComment) {
            return getDescription(this.comment)
          }*/
          return this.h.message
        },
        isSystemComment () {
          return ['modify.unfollow', 'modify.follow', 'create'].includes(this.h.action)
        },
        list() {
          if (this.obj && this.obj.list) {
            return this.obj.list
          }
          return [this.obj]
        }
      },
      methods: {},
      components: {TaskFile, UTooltip}
    }
</script>

<template>
    <div class="task-comment" :class="classes">
        <div class="comment">
            <div class="comment-info" v-if="!isSystemComment">
                <span class="user">{{firstLastName(h.person)}}</span>
                <span class="date">
                    {{h.date | formatDate}}
                    <u-tooltip :offset="[2,2]">{{h.date.date|formatDateDistance}}</u-tooltip>
                </span>
                <!--<span class="comment-history">
                    <a class="comment-history-btn">
                        <u-icon name="comment-lines" type="fa" icon-style="light"></u-icon>
                        <u-tooltip :offset="[2,2]">Ver conversa</u-tooltip>
                    </a>
                </span>--> <!-- @TODO -->
            </div>
            <div class="description">
              <div v-if="h.action === 'create'" class="description-content"><a @click="gotoPerson({id: h.personId})">{{firstLastName(h.person)}}</a> criou esta tarefa</div>
              <div v-else-if="action === 'follow'" class="description-content"><span class="text-positive font-bold">+1</span> <a @click="gotoPerson({id: h.personId})">{{firstLastName(h.person)}}</a> começou a seguir esta tarefa</div>
              <div v-else-if="action === 'unfollow'" class="description-content"><span class="text-negative font-bold">-1</span> <a @click="gotoPerson({id: h.personId})">{{firstLastName(h.person)}}</a> deixou de seguir esta tarefa</div>
              <div v-else-if="action === 'comment'" class="description-content">
                <div v-html="obj.text"></div>
              </div>
              <div v-else-if="h.action === 'file.create'" class="description-content">
                <div>
                  <div class="task-files m-b-lg">
                    <ul>
                      <li v-for="(o, index) in list" :key="index" :class="{'box-diviser': list.length > 1}">
                        <task-file :h="h" :file="o"/> <span class="desc"></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-else class="description-content">{{description}}</div>
              <div class="description-date" v-if="isSystemComment">{{h.date|formatDate}}</div>
            </div>
        </div>
    </div>
</template>
